"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var WebViewService = (function () {
    function WebViewService() {
        this.webView = window && window.ReactNativeWebView;
    }
    WebViewService.prototype.isWebView = function () {
        return !!this.webView;
    };
    WebViewService.prototype.sendEvent = function (event) {
        var _a;
        (_a = this.webView) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify(event));
    };
    return WebViewService;
}());
exports.default = new WebViewService();
