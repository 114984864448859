"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var defaultLang = 'en';
var navigatorLang = (navigator.language || navigator.userLanguage || defaultLang).split('-')[0];
var supportedAppLangs = ['en', 'es'];
var LangService = (function () {
    function LangService() {
        var _this = this;
        this.appLang = supportedAppLangs.includes(navigatorLang) ? navigatorLang : defaultLang;
        this.comicLang = defaultLang;
        this.getLabel = function (labelId, forceAppLang) {
            if (forceAppLang === void 0) { forceAppLang = false; }
            if (typeof labelId === 'string') {
                return appLabels[labelId] ? appLabels[labelId][_this.appLang] : '';
            }
            else if (typeof labelId === 'object') {
                return labelId[_this.appLang] || labelId[_this.comicLang] || labelId[defaultLang];
            }
            return '';
        };
    }
    LangService.prototype.initialize = function () {
    };
    LangService.prototype.getComicLang = function () {
        return this.comicLang;
    };
    LangService.prototype.setComicLang = function (comicLang) {
        this.comicLang = comicLang;
    };
    return LangService;
}());
var appLabels = {
    en: {
        en: 'English',
        es: 'Inglés',
    },
    es: {
        en: 'Spanish',
        es: 'Español',
    },
    by: {
        en: 'By',
        es: 'Por',
    },
    readOne: {
        en: 'Read The Comic',
        es: 'Leer el Comic',
    },
    readFirst: {
        en: 'Read First Episode',
        es: 'Leer el Primer Episodio',
    },
    nowReading: {
        en: 'Now Reading',
        es: 'Estás Leyendo',
    },
    commentTitle: {
        en: 'Comment and Share',
        es: 'Comenta y Comparte',
    },
    authorPhd: {
        en: 'Your name',
        es: 'Tu nombre',
    },
    backToComicBtn: {
        en: 'Back to Comic',
        es: 'Regresar al Comic',
    },
    episodeListBtn: {
        en: 'Episode List',
        es: 'Lista de Episodios',
    },
    leaveACommentBtn: {
        en: 'Leave a Comment!',
        es: 'Deja un Comentario!',
    },
    emailPhd: {
        en: 'Your email (optional, in case you use gravatar)',
        es: 'Tu email (opcional, en caso de que uses gravatar)',
    },
    contentPhd: {
        en: 'Write your comment here',
        es: 'Escribe tu comentario aquí',
    },
    commentBtn: {
        en: 'Comment',
        es: 'Comentar',
    },
    noCommentsTitle: {
        en: 'There are no comments yet',
        es: 'No hay comentarios todavía',
    },
    noCommentsContent: {
        en: 'Be the first one to leave a comment!',
        es: 'Sé el primero en dejar un comentario!',
    },
    share: {
        en: 'Share!',
        es: 'Comparte!',
    },
    featured: {
        en: 'Featured',
        es: 'Recomendado',
    },
    language: {
        en: 'Change Language',
        es: 'Cambiar Idioma',
    },
    availableLangs: {
        en: 'Available Languages',
        es: 'Idiomas Disponibles',
    },
    enjoyedTheEpisode: {
        en: 'Enjoyed the episode?',
        es: '¿Te gustó este episodio?',
    },
    nextEpisodeBtn: {
        en: 'Next Episode',
        es: 'Siguente Episodio',
    },
    episodePasswordTitle: {
        en: 'This episode is password protected',
        es: 'Este episodio está protegido',
    },
    episodePasswordDefaultContent: {
        en: 'The episode you are trying to read requires a password. Please contact the comic author to provide it.',
        es: 'El episodio que estás intentanddo leer requiere de una contraseña. Por favor contacta al autor del comic para que te la provea.',
    },
    continueBtn: {
        en: 'Continue',
        es: 'Continuar',
    },
    passwordPhd: {
        en: 'Password',
        es: 'Contraseña',
    },
    passwordIncorrect: {
        en: 'Incorrect password',
        es: 'Contraseña incorrecta',
    }
};
exports.default = new LangService();
