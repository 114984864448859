"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRandomEmail = exports.getRandomNumber = exports.generateUuid = void 0;
function generateUuid(a, b) {
    for (b = a = ''; a++ < 36; b += (a * 51) & 52 ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16) : '-')
        ;
    return b;
}
exports.generateUuid = generateUuid;
function getRandomNumber(digits) {
    if (digits === void 0) { digits = 8; }
    return Math.floor(Math.random() * Math.pow(10, digits));
}
exports.getRandomNumber = getRandomNumber;
function getRandomEmail() {
    return "".concat(getRandomNumber(), "@thedreamcatchercomic.com");
}
exports.getRandomEmail = getRandomEmail;
